<template>
    <!-- Video Duration -->
    <div
        v-if="item.duration"
        :class="durationClass"
        class="border-radius-[5px] inline-flex h-[20px] w-auto rounded bg-[rgba(10,10,10,0.29)] px-[4px] text-[12px] leading-[20px] tracking-[.36px] text-white">
        {{ item.duration }}
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue'
    import { usePage } from '@inertiajs/vue3'

    const props = defineProps({
        item: {
            type: Object,
            required: true,
        },
        scenePage: {
            type: Boolean,
            default: false,
        },
    })

    const { props: p } = usePage()

    const isLoggedIn = ref(p.auth && p.auth.user && p.auth.user.name !== null)

    const durationClass = computed(() => {
        return props.scenePage ? 'relative top-0 left-0' : 'absolute bottom-[11px] left-[8px]'
    })
</script>

<style scoped>
    @media screen and (max-width: 640px) {
        .explore-page .unlock-button br {
            display: block;
        }
    }
</style>
